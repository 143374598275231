<i18n>
{
  "en": {
    "browser_title": "Series"
  },
  "ja": {
    "browser_title": "シリーズ"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="series">
      <series-list :hide-series="['リゾート', 'フェチ', 'ポップ', 'エレガンス', 'ギャル', 'グラビア', 'スペシャル', 'セレブ', 'プチ']" />
    </div>
  </main>
</template>

<script>
/* eslint max-len: 0 */
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import SeriesList from '@/components/series/seriesList.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'series-list': SeriesList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_category.scss';
</style>
