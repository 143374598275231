<i18n>
{
  "en": {
    "series": "Series"
  },
  "ja": {
    "series": "シリーズ一覧"
  }
}
</i18n>

<template>
<main>
  <div class="contents">
    <div class="heading">
      <h1>{{ $t('series') }}</h1>
    </div>
    <ul class="flex-list list-series">
      <li class="list-item"
        v-for="item in seriesList"
        :key="item.series_id"
        @click="clickTrack('Series Page', item.name.ja);"
      >
        <router-link
          :to="`/search/?sr=${item.series_id}`"
          class="list-button"
        >
          {{ item.name[locale] }}<span class="iconf-arrow-right"></span>
        </router-link>
      </li>
    </ul>
  </div>
</main>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    hideSeries: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      seriesList: [],
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();

    const result = await bfAPI.getSeries();
    Object.keys(result).forEach((key) => {
      if (!this.hideSeries.includes(result[key].name.ja)) this.seriesList.push(result[key]);
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
